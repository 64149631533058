import React from "react";
import styled from "styled-components";
interface Props {
  left: number;
  top: number;
  children: React.ReactNode;
}
const GraphItem = (props: Props) => {
  return (
    <GraphItemElement left={props.left} top={props.top}>
      {props.children}
    </GraphItemElement>
  );
};

const GraphItemElement = styled.div<{
  left: number;
  top: number;
}>`
  position: absolute;
  background-color: #5f5f5f;
  color: #fff;
  border-radius: 4px;
  padding: 6px 12px;
  font-weight: bold;
  left: ${(props) => (props.left ? props.left : 0)}px;
  top: ${(props) => (props.top ? props.top : 0)}px;
`;

export default GraphItem;
