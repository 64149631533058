import React from "react";
import saru from "../src/img/saru.png";
import Instagram from "../src/img/Instagram.png";
import Linkedin from "../src/img/LinkedIn.png";
import X from "../src/img/X.png";
import styled from "styled-components";
import AnchorLink from "../src/components/AnchorLink";
import GraphItem from "../src/components/GraphItem";
import "./App.css";

const App = () => {
  return (
    <AppElement>
      <Content>
        <Section>
          <IconSaru>
            <img src={saru} alt="logo" />
          </IconSaru>
          <Text>
            Hello I am designer soyakimu who like onsen & beer <br />
            Please be sure you at least remember my icon by the time you leave.
          </Text>
          <IconLists>
            <AnchorLink href="https://www.linkedin.com/in/soyakimu/">
              <img src={Linkedin} alt="Linkedin" width={48} height={48} />
            </AnchorLink>
            <AnchorLink href="https://twitter.com/soyakimu">
              <img src={X} alt="X" width={48} height={48} />
            </AnchorLink>
            <AnchorLink href="https://www.instagram.com/soyakimu/">
              <img src={Instagram} alt="Instagram" width={48} height={48} />
            </AnchorLink>
          </IconLists>
        </Section>
        <Section>
          <Title>10 Skills Map</Title>
          <Graph>
            <VerticalLine />
            <VerticalLineText>like</VerticalLineText>
            <HorizontalLine />
            <HorizontalLineText>specialty</HorizontalLineText>
            <GraphItem top={0} left={250}>
              UI Design
            </GraphItem>
            <GraphItem top={20} left={110}>
              Visual Design
            </GraphItem>
            <GraphItem top={50} left={280}>
              Web Design
            </GraphItem>
            <GraphItem top={60} left={50}>
              Generative AI
            </GraphItem>
            <GraphItem top={100} left={80}>
              English
            </GraphItem>
            <GraphItem top={100} left={200}>
              UX
            </GraphItem>
            <GraphItem top={100} left={294}>
              Front-End
            </GraphItem>
            <GraphItem top={150} left={150}>
              Analysis
            </GraphItem>
            <GraphItem top={190} left={190}>
              Information Design
            </GraphItem>
            <GraphItem top={250} left={140}>
              Writing
            </GraphItem>
          </Graph>
        </Section>
        <Section>
          <Title>Career</Title>
          <Timeline>
            <li>
              <TimelineDate>
                Apr 2019
                <br />
                -
                <br />
                <strong>Present</strong>
              </TimelineDate>
              <TimelineContent>
                <h3>
                  Yahoo! JAPAN - UI/UX Designer
                  <small>[Main Job]</small>
                </h3>
                <ul>
                  <li>
                    Oct 2022 :{" "}
                    <a href="https://www.g-mark.org/gallery/winners/7924">
                      Yahoo! JAPAN Mail Team GOOD DESIGN AWARD 2022
                    </a>
                  </li>
                  <li>
                    Apr 2020 - Present : Yahoo! JAPAN Mail (PC/SmartPhoneWeb)
                  </li>
                  <li>Apr 2019 - Apr 2020 : Yahoo! JAPAN Mail App (iOS)</li>
                </ul>
              </TimelineContent>
            </li>
            <li>
              <TimelineDate>
                Jun 2022
                <br />
                -
                <br />
                <strong>Present</strong>
              </TimelineDate>
              <TimelineContent>
                <h3>
                  NewStory, Inc. - Designer/AppDevelopment
                  <small>[Second Job]</small>
                </h3>
                <ul>
                  <li>App development, design, promotion, etc</li>
                </ul>
              </TimelineContent>
            </li>
            <li>
              <TimelineDate>
                Mar 2016
                <br />
                -
                <br />
                Feb 2019
              </TimelineDate>
              <TimelineContent>
                <h3>Internship & Part-Time job</h3>
                <ul>
                  <li>Aug 2017 : Yahoo! JAPAN - Internship</li>
                  <li>Jan 2018 : A-Team - Internship</li>
                  <li>Sep 2018 : Eureka, Inc. - Internship</li>
                  <li>
                    Mar 2016 - Feb 2019 : Kiss, Inc. - Part-Time job (Web
                    design, Coding)
                  </li>
                </ul>
              </TimelineContent>
            </li>
            <li>
              <TimelineDate>
                Apr 2014
                <br />
                -
                <br />
                Mar 2019
              </TimelineDate>
              <TimelineContent>
                <h3>Education</h3>
                <ul>
                  <li>
                    Apr 2017 - Mar 2019 : <br />
                    Shinshu University - Graduate School of Science and
                    Technology, Electrical and Computer Engineering Division
                  </li>
                  <li>
                    Apr 2014 - Mar 2017 : <br />
                    Shinshu University - Undergraduate, Faculty of Engineering,
                    Department of Computer Science and Engineering
                  </li>
                </ul>
              </TimelineContent>
            </li>
          </Timeline>
        </Section>
        <Section>
          <TitleWithHint>Private Design Works</TitleWithHint>
          <Hint>
            If you want to know my design works for job, please contact me
          </Hint>
          <DesignList>
            <li>
              <DesignCard href="http://www.kanax.info/">
                <p>
                  KANAX Co. Ltd.
                  <br />
                  Home Page
                </p>
              </DesignCard>
              <DesignDescription>
                <strong>Points</strong>
                <ul>
                  <li>Renewal of old website</li>
                  <li>Information design of website</li>
                  <li>Coding with wordpress</li>
                  <li>Create design guide</li>
                </ul>
              </DesignDescription>
            </li>
            <li>
              <DesignCard href="https://soyakimu.github.io/tedxshinshuuniversity/index.html">
                <p>
                  TEDx
                  <br />
                  ShinshuUniv.
                  <br />
                  Archive Page
                </p>
              </DesignCard>
              <DesignDescription>
                <strong>Points</strong>
                <ul>
                  <li>Create everything from design concept</li>
                  <li>Coding of animations</li>
                  <li>All created by myself</li>
                </ul>
              </DesignDescription>
            </li>
            <li>
              <DesignCard href="https://soyakimu.github.io/craftbeermonkey/">
                <p>
                  CraftBeerMonkey
                  <br />
                  LINE Sticker
                </p>
              </DesignCard>
              <DesignDescription>
                <strong>Points</strong>
                <ul>
                  <li>Create illustrations</li>
                  <li>Created entirely as a hobby</li>
                  <li>All created by myself</li>
                </ul>
              </DesignDescription>
            </li>
          </DesignList>
        </Section>
        <Section>
          <Contact>
            <TitleWithHint>Contact</TitleWithHint>
            <Hint>
              Please feel free to contact me from the following three SNS
            </Hint>
            <IconLists>
              <AnchorLink href="https://www.linkedin.com/in/soyakimu/">
                <img src={Linkedin} alt="Linkedin" width={48} height={48} />
              </AnchorLink>
              <AnchorLink href="https://twitter.com/soyakimu">
                <img src={X} alt="X" width={48} height={48} />
              </AnchorLink>
              <AnchorLink href="https://www.instagram.com/soyakimu/">
                <img src={Instagram} alt="Instagram" width={48} height={48} />
              </AnchorLink>
            </IconLists>
          </Contact>
        </Section>
        <Footer>©soyakimu</Footer>
      </Content>
    </AppElement>
  );
};
const AppElement = styled.div`
  background-color: #424242;
  color: #fff;
`;
const IconSaru = styled.div`
  justify-content: center;
  display: flex;
  img {
    height: 150px;
    width: 150px;
    pointer-events: none;
    animation: App-logo-spin infinite 10s linear;
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
const Content = styled.div`
  width: 650px;
  margin: 0 auto;
`;
const Title = styled.h2`
  font-size: 26px;
  padding-bottom: 30px;
  font-weight: bold;
  text-align: center;
`;
const TitleWithHint = styled.h2`
  font-size: 26px;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: center;
`;
const Hint = styled.p`
  font-size: 16px;
  padding-bottom: 30px;
  text-align: center;
`;
const Graph = styled.div`
  width: 450px;
  margin: 0 auto;
  height: 350px;
  position: relative;
`;
const VerticalLine = styled.div`
  width: 2px;
  height: 350px;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 30px;
`;
const VerticalLineText = styled.div`
  position: absolute;
  top: -24px;
  left: 18px;
  font-weight: bold;
`;
const HorizontalLine = styled.div`
  width: 400px;
  height: 2px;
  position: absolute;
  background-color: #fff;
  bottom: 30px;
`;
const HorizontalLineText = styled.div`
  position: absolute;
  bottom: 24px;
  right: -30px;
  font-weight: bold;
`;
const Text = styled.p`
  width: 400px;
  margin: 30px auto;
  text-align: left;
`;
const Section = styled.section`
  text-align: center;
  padding: 60px 0;
`;
const IconLists = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin: 0 10px;
  }
`;
const Timeline = styled.ul`
  list-style: none;
  padding-left: 0;
  & > li {
    overflow: hidden;
    margin: 0;
    position: relative;
  }
`;
const TimelineDate = styled.p`
  width: 110px;
  float: left;
  margin-top: 24px;
  text-align: right;
  font-size: 14px;
  line-height: 16px;
  margin-right: 20px;
`;
const TimelineContent = styled.div`
  width: 480px;
  float: left;
  border-left: 2px #fff solid;
  padding-left: 30px;
  text-align: left;
  &:before {
    content: "";
    width: 22px;
    height: 22px;
    background: #424242;
    border: 2px solid #fff;
    position: absolute;
    left: 120px;
    top: 38px;
    border-radius: 100%;
  }
  h3 {
    margin: 40px 0 8px;
    small {
      display: block;
      position: absolute;
      top: 22px;
      font-size: 14px;
      color: rgb(255 255 255 / 70%);
    }
  }
  ul {
    padding-left: 16px;
    margin-bottom: 10px;
    list-style: disc;
    li {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

const DesignList = styled.ul`
  padding: 0;
  width: 500px;
  margin: 0 auto 20px;
  > li {
    display: flex;
    margin-bottom: 20px;
  }
`;
const DesignCard = styled.a`
  width: 180px;
  height: 114px;
  border: 3px solid #fff;
  position: relative;
  color: #fff;
  margin-right: 30px;
  p {
    position: absolute;
    width: 130px;
    text-align: left;
    font-weight: bold;
    line-height: 20px;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`;
const DesignDescription = styled.div`
  font-size: 14px;
  text-align: left;
  margin: auto 0;
  ul {
    padding-left: 20px;
    li {
      list-style: disc;
    }
  }
`;
const Contact = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 60px;
  background-color: rgb(217 217 217 / 10%);
`;
const Footer = styled.footer`
  text-align: center;
  padding: 60px;
`;

export default App;
