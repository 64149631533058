import React from "react";
import styled from "styled-components";
interface Props {
  fontSize: number;
  href: string;
  children: React.ReactNode;
}
const AnchorLink = (props: Props) => {
  return (
    <AnchorLinkElement href={props.href} fontSize={props.fontSize}>
      {props.children}
    </AnchorLinkElement>
  );
};
AnchorLink.defaultProps = {
  fontSize: 14,
};
const AnchorLinkElement = styled.a<{
  fontSize: number;
}>`
  font-size: ${(props) => props.fontSize};
`;

export default AnchorLink;
